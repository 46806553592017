<template>
  <div id="page_not_found">
    <breadcrumbs :with-homepage="true" :routes="[]" />
    <div class="container">
      <h2 class="page-title">
        {{ $t("We can't find the page") }} ...
      </h2>
    </div>

    <div class="container">
      <div class="static-content">
        <p>
          {{ $t("Unfortunately we can't find the page you are looking for.") }}
        </p>
        <p>
          {{ $t('If you need an assistance you can drop us a line on') }}:
          <a href="mailto:info@zaun24.de" class="cl-secondary no-underline">
            info@zaun24.de
          </a>
          <br>
          {{ $t('You can also use') }}
          <a href="#" class="cl-secondary no-underline" @click="toggleSearchpanel">
            {{ $t('search') }}
          </a>
          {{ $t('to find product you were looking for.') }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { Logger } from '@vue-storefront/core/lib/logger'
import i18n from '@vue-storefront/i18n'
import Breadcrumbs from 'theme/components/core/Breadcrumbs'

export default {
  name: 'PageNotFound',
  computed: {
    ...mapGetters({
      ourBestsellersCollection: 'homepage/getBestsellers'
    })
  },
  async asyncData ({ store, route, context }) {
    Logger.log('Entering asyncData for PageNotFound ' + new Date())()
    if (context) {
      context.output.cacheTags.add('page-not-found')
      context.server.response.statusCode = 404
    }
  },
  metaInfo () {
    return {
      title: this.$route.meta.title || i18n.t('404 Page Not Found'),
      meta: this.$route.meta.description ? [{ vmid: 'description', name: 'description', content: this.$route.meta.description }] : []
    }
  },
  components: {
    Breadcrumbs
  },
  methods: {
    toggleSearchpanel () {
      this.$store.commit('ui/setSearchpanel', true)
    }
  }
}
</script>

<style scoped>
  a {
    text-decoration: underline;
  }
</style>
